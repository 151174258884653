import React from "react"

import SkeletonLink from "components/common/skeleton-link"
import Share from "components/modals/share"
import { Contact, CGV, Partners } from "components/modals"
import Image from "components/common/image"

import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { overlayDataLayer } from "lib/gtm/interactions"
import { useLanguages } from "lib/languages"

import DownloadAppButton from "./download-app-button"
import FooterApp from "static/images/footer/footer-app.svg"
import LogoFr from "static/assets/images/logo-fr.svg"
import LogoEn from "static/assets/images/logo-en.svg"
import LogoDe from "static/assets/images/logo-de.svg"

import { SocialBar } from "./social-bar"

const Footer = ({ backOffice }) => {
	if (backOffice) {

		return null
	}

	const { show } = useModal()
	const { c } = useTranslation("common")
	const { current } = useLanguages()

	const logos = {
		fr: LogoFr,
		en: LogoEn,
		de: LogoDe
	}

	const Logo = logos[current]

	const showOverlay = (id, title, Component, className) => {
		show({
			id,
			title: c(title),
			fixedHeader: true,
			children: <Component />,
			className
		})
	}

	const showShare = () => {
		overlayDataLayer("Partager");
		show({
			id: "share-this",
			children: <Share />,
			className: "modal left bottom interactive red"
		})
	}

	return (
		<footer>
			<section>
				<h2 className="hidden">{c("site-footer")}</h2>
				<div className="columns">
					<SkeletonLink href="/">
						<a title={c("back-homepage")} className="lacinetek">
							<Logo title={c("lacinetek-logo")} />
							<span className="label">{c("cinetek-title")} </span>
						</a>
					</SkeletonLink>
					<nav className="secondary" aria-label={c("secondary-navigation")}>
						<a
							href="https://lacinetekhelp.zendesk.com/hc/fr/sections/360003195934-Questions-fr%C3%A9quentes"
							target="_blank"
							title={c("faq-support")}
							aria-label={`${c("to-external-site")} ${c("faq-support")}`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							{c("faq-support")}
						</a>
						<a
							href="#"
							onClick={e => { e.preventDefault(); overlayDataLayer("Contact"); showOverlay("contact", "contact-us", Contact, "slide short left") }}
							rel="nofollow noopener"
							title={c("contact-us")}
							data-overlay="#overlay-contact"
						>
							{c("contact-us")}
						</a>
						<a
							href="#"
							id="ot-sdk-btn"
							className="ot-sdk-show-settings temp-cookie-pro-style-cancellation"
							onClick={e => { e.preventDefault() }}
							rel="nofollow noopener"
							title={c("cookie-settings")}
						>
							{c("cookie-settings")}
						</a>
						<a
							href="#"
							onClick={e => { e.preventDefault(); overlayDataLayer("CGVU"); showOverlay("cgv", "complete-cgvu", CGV) }}
							rel="nofollow noopener"
							title={c("cgvu")}
							data-overlay="#overlay-cgvu"
						>
							{c("cgvu")}
						</a>
						<a
							href="#"
							onClick={e => { e.preventDefault(); overlayDataLayer("Partenaires"); showOverlay("partners", "partners", Partners) }}
							rel="nofollow noopener"
							title={c("partners")}
							data-overlay="#overlay-partners"
						>
							{c("partners")}
						</a>
					</nav>
					<div>
						<h3 className="title">{c("follow-us")} </h3>
						<SocialBar />
					</div>
				</div>
			</section>
			<section>
				<div className="offers apps">
					<figure className="devices">
						<FooterApp />
					</figure>
					<p className="excerpt">
						<strong>{c("footer-everywhere-everytime")}</strong>
					</p>
					<p dangerouslySetInnerHTML={{ __html: c("footer-app-disclaimer") }}>
					</p>
					<nav className="inline stores">
						<DownloadAppButton platform="apple" />
						<DownloadAppButton platform="google" />
					</nav>
				</div>
			</section>
			<section className="website-credits centered">
				<h3 className="title">{c("original-members")}</h3>
				<p>Pascale Ferran, Cédric Klapisch, Laurent Cantet ({c("directors")}) {c("and")}  Alain Rocca.</p>
				<h3 className="title">{c("directors-member-of-cinematheque")}</h3>
				<p>{c("arp-representative")}</p>
				<h3 className="title">{c("organizations-member-of-cinematheque")}</h3>
				<ul className="inline partners">
					<li>
						<a
							title="La Société des Réalisateurs de Films"
							href="http://www.la-srf.fr/"
							target="_blank"
							aria-label={`${c("to-external-site")} La Société des Réalisateurs de Films`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/partenaire_srf.png"
										alt="La Société des Réalisateurs de Films"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="UniversCiné"
							href="http://www.universcine.com/"
							target="_blank"
							aria-label={`${c("to-external-site")} UniversCiné`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/logo-universcine.png"
										alt="UniversCiné"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="Arte"
							href="http://cinema.arte.tv/fr"
							target="_blank"
							aria-label={`${c("to-external-site")} Arte`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/membre_arte.png"
										alt="Arte"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="INA"
							href="https://www.ina.fr/"
							target="_blank"
							aria-label={`${c("to-external-site")} INA`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/membre-INA.png"
										alt="INA"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="La Cinémathèque de Toulouse"
							href="http://www.lacinemathequedetoulouse.com/"
							target="_blank"
							aria-label={`${c("to-external-site")} La Cinémathèque de Toulouse`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/membre-cinematheque-de-toulouse.png"
										alt="La Cinémathèque de Toulouse"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="ARP"
							href="http://www.larp.fr/"
							target="_blank"
							aria-label={`${c("to-external-site")} ARP`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/membre-ARP.png"
										alt="ARP"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="Lobster"
							href="http://www.lobsterfilms.com"
							target="_blank"
							aria-label={`${c("to-external-site")} Lobster`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/membre-lobster-films.jpg"
										alt="Lobster"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="StudioCanal"
							href="http://www.studiocanal.com/"
							target="_blank"
							aria-label={`${c("to-external-site")} StudioCanal`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/membre-studiocanal.jpg"
										alt="StudioCanal"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="Gaumont"
							href="http://www.gaumont.fr"
							target="_blank"
							aria-label={`${c("to-external-site")} Gaumont`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/membre-gaumont.png"
										alt="Gaumont"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="La Cinémathèque française"
							href="http://www.cinematheque.fr/"
							target="_blank"
							aria-label={`${c("to-external-site")} La Cinémathèque française`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/logo-la-cinematheque-francaise-membre.png"
										alt="La Cinémathèque française"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="Centre Pompidou"
							href="https://www.centrepompidou.fr"
							target="_blank"
							aria-label={`${c("to-external-site")} Centre Pompidou`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/logo-centre-pompidou.png"
										alt="Centre Pompidou"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="Deutsche Kinemathek"
							href="https://www.deutsche-kinemathek.de/en"
							target="_blank"
							aria-label={`${c("to-external-site")} Deutsche Kinemathek`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/logo-kinemathek-de.png"
										alt="Deutsche Kinemathek"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="Agence du Court Métrage"
							href="https://www.agencecm.com/"
							target="_blank"
							aria-label={`${c("to-external-site")} Agence du Court Métrage`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/logo-ACM.png"
										alt="Agence du Court Métrage"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="La Cinémathèque royale de Belgique"
							href="https://cinematek.be/fr"
							target="_blank"
							aria-label={`${c("to-external-site")} La Cinémathèque royale de Belgique`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/logo-CRB.png"
										alt="logo La Cinémathèque royale de Belgique"
									/>
								</picture>
							</figure>
						</a>
					</li>
				</ul>
				<h3 className="title">{c("supported-by")}</h3>
				<ul className="inline partners">
					<li>
						<a
							title="Centre National du Cinéma et de l'image animée"
							href="https://www.cnc.fr"
							target="_blank"
							aria-label={`${c("to-external-site")} Centre National du Cinéma et de l'image animée`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/soutien-centre-national-cinematographie.png"
										alt="Centre National du Cinéma et de l'image animée"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="Europe créative MEDIA"
							href="https://eacea.ec.europa.eu/europe-creative/media_fr"
							target="_blank"
							aria-label={`${c("to-external-site")} Europe créative MEDIA`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/soutien-media.png"
										alt="Europe créative MEDIA"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="Île de France"
							href="https://www.iledefrance.fr/"
							target="_blank"
							aria-label={`${c("to-external-site")} Île de France`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/soutien_ile-de-france.png"
										alt="Île de France"
									/>
								</picture>
							</figure>
						</a>
					</li>
					<li>
						<a
							title="Chanel"
							href="https://www.chanel.com/fr/mode/evenement/chanel-et-le-cinema/"
							target="_blank"
							aria-label={`${c("to-external-site")} Chanel`}
							rel="nofollow noopener"
						>
							<span className="hidden">{c("external-link")}</span>
							<figure>
								<picture>
									<Image
										src="/static/images/footer/soutien_chanel.png"
										alt="Chanel"
									/>
								</picture>
							</figure>
						</a>
					</li>
				</ul>
				<h3 className="title">{c("thanks-credits")}</h3>
				<p>Cellules, Eric Brocherie, Les Produits Frais, Ricochets Productions, Cécile Dubost, Léo Caresio, Pierre Laporte Communication, Kinow, Codekraft, Hybrid {c("and")} Middlemotion</p>
			</section>
			<nav className="nav-site" aria-label={c("site-navigation")}>
				<a
					href="#"
					onClick={e => { e.preventDefault(); showShare() }}
					rel="nofollow noopener"
					title={c("share")}
					data-overlay="#overlay-share-this"
					className="icon-share share-this"
				>
					{c("share")}
				</a>
				<a
					href="#top"
					title={c("top-of-page")}
					rel="nofollow noopener"
					className="scroll-up"
				>
					{c("top-of-page")}
				</a>
			</nav>
		</footer>
	)
}

export default Footer
